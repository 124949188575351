.nav-bar {
  background: #181818;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  @media only screen and (max-width: 960px) {
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    min-height: 0;
  }

  .ki-logo {
    display: block;
    padding: 8px;
    margin: 8px auto;
    width: 30px;
    height: auto;
    animation: colorRotate 10s linear infinite;
    border: none;

    @media only screen and (max-width: 960px) {
      display: block;
      margin: 0 15px;
      height: 80%;
    }

    @media only screen and (max-width: 530px) {
      display: none;
      margin: 0;
      padding: 0;
    }
  }

  p {
    color: white;
    text-decoration: none;
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;
    z-index: 99;

    @media only screen and (max-width: 960px) {
      width: 42%;
      position: absolute;
      left: 50%;
      margin-left: -20%;
      float: left;
      overflow: hidden;
      text-align: center;
      height: 70px;
      top: 0;
      margin-top: 0;
    }

    @media only screen and (max-width: 530px) {
      width: 100vw;
      left: 15%;
      display: block;
      margin: 0 auto;
    }

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      position: relative;
      text-decoration: none;
      z-index: 9999;

      @media only screen and (max-width: 960px) {
        float: left;
        margin: 12px 6%;
        height: 55px;
        font-size: 28px;
      }

      @media only screen and (max-width: 530px) {
        margin: 10px 8%;
        font-size: 24px;
      }

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffb580;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;

        @media only screen and (max-width: 960px) {
          letter-spacing: 1px;
        }
      }

      &:first-child {
        @media only screen and (max-height: 960px) {
          margin-left: 0;
        }

        &::after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.category-link {
      &:after {
        content: 'PROJECTS';
      }
    }

    a.active {
      color: #ffb580;
    }
  }
}

ul {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  @media only screen and (max-width: 960px) {
    position: absolute;
    top: 0;
    right: 15px;
    width: auto;
    height: 70px;
    line-height: 70px;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    float: right;
  }

  li {
    a {
      padding: 7px 0;
      display: block;
      font-size: 20px;
      line-height: 16px;

      &:hover svg {
        color: #ffb580;
      }

      @media only screen and (max-width: 960px) {
        line-height: 60px;
        padding: 0;
      }
    }

    @media only screen and (max-width: 960px) {
      float: right;
      height: 40px;
      line-height: 60px;
      margin: 10px;
    }

    @media only screen and (max-width: 530px) {
      display: none;
    }
  }
}

@keyframes colorRotate {
  from {
    fill: #ff8080;
    stroke: #ff8080;
  }
  12.5% {
    fill: #ffb580;
    stroke: #ffb580;
  }
  25% {
    fill: #ffea80;
    stroke: #ffea80;
  }
  37.5% {
    fill: #8aff80;
    stroke: #8aff80;
  }
  50% {
    fill: #80ffdf;
    stroke: #80ffdf;
  }
  62.5% {
    fill: #80b5ff;
    stroke: #80b5ff;
  }
  75% {
    fill: #8a80ff;
    stroke: #8a80ff;
  }
  87.5% {
    fill: #d580ff;
    stroke: #d580ff;
  }
  100% {
    fill: #ff80ca;
    stroke: #ff80ca;
  }
  0% {
    fill: #ff80ca;
    stroke: #ff80ca;
  }
}
