.contact-form {
  width: 100%;
  margin-top: 20px;
  z-index: 999;

  label {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    opacity: 0;
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }

  label.half {
    width: 49%;
    margin-left: 2%;
    float: left;
    clear: none;

    &:first-child {
      margin-left: 0;
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #b0b0e5;
    height: 50px;
    font-size: 16px;
    color: #181818;
    padding: 0 20px;
    box-sizing: border-box;

    &::placeholder {
      color: #333366;
    }
  }

  textarea {
    width: 100%;
    border: 0;
    background: #b0b0e5;
    font-size: 16px;
    color: #181818;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;

    &::placeholder {
      color: #333366;
    }
  }

  .flat-button {
    color: #ffb580;
    font-size: 15px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 18px 25px;
    border: 1px solid #ffb580;
    float: left;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
      background-color: #ffb580;
      color: #333366;
      border: 1px solid #333366;
    }
  }
}

.map-wrap {
  background: #b0b0e5;
  width: 53%;
  height: 100%;
  margin-right: 1%;
  float: right;

  @media only screen and (max-width: 960px) {
    display: none;
  }

  .info-map {
    position: absolute;
    background-color: #181818;
    top: 50px;
    right: 9%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #ffffff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    a {
      display: inline-block;
      color: #ffb580;
      text-decoration: none;
    }
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}
