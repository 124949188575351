.home-page {
  .tab {
    width: 70px;

    @media only screen and (max-width: 960px) {
      display: none;
    }
  }
  .text-zone {
    position: absolute;
    left: calc(10% + 60px);
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;

    @media only screen and (max-width: 960px) {
      width: 50%;
    }
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    .first-name {
      font-family: 'Paytone One', sans-serif;
      font-size: 64px;
      font-weight: 400;
      padding: 0 8px;
    }
  }

  h2 {
    color: #ffb580;
    margin-top: 20px;
    font-weight: 400;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .button-container {
    width: 100%;
    right: 15%;
    margin-top: 35px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 960px) {
      margin-left: 15px;
    }

    .flat-button {
      color: #fff;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 15px;
      margin: 0 2%;
      border: 1px solid #fff;
      animation: fadeIn 1s 1.8s backwards;
      white-space: nowrap;

      &:hover {
        background: #ffb580;
        border: 1px solid #ffb580;
        color: #333366;
        font-weight: 600;
      }
    }
  }
}

.ki-icon {
  @media only screen and (max-width: 960px) {
    display: none;
  }
  z-index: -2;
  position: absolute;
  margin-left: 5%;
  top: 50%;
  transform: translateY(-50%);
  border: 10px solid #fff;
  border-radius: 100%;
  animation: colorRotateBorder 10s linear infinite;
}

@keyframes colorRotateBorder {
  from {
    border: 10px solid #ff8080;
  }
  12.5% {
    border: 10px solid #ffb580;
  }
  25% {
    border: 10px solid #ffea80;
  }
  37.5% {
    border: 10px solid #8aff80;
  }
  50% {
    border: 10px solid #80ffdf;
  }
  62.5% {
    border: 10px solid #80b5ff;
  }
  75% {
    border: 10px solid #8a80ff;
  }
  87.5% {
    border: 10px solid #d580ff;
  }
  100% {
    border: 10px solid #ff80ca;
  }
  0% {
    border: 10px solid #ff80ca;
  }
}
