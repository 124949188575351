.freelance-page {
  overflow-y: auto;

  .images-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .frame {
    position: relative;
    width: calc(100% / 5);
    height: 450px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    box-sizing: border-box;

    @media only screen and (max-width: 960px) {
      width: calc(100% / 3);
    }

    .cover-image {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
      place-content: center;
      filter: brightness(40%);
    }

    .content {
      position: absolute;
      z-index: 3;
      padding: 10px 0;
      width: 100%;
      transition: cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
        180deg,
        rgba(#ffb580, 0) 0,
        rgba(#000000, 0.5) 50%,
        #000000
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      padding: 0 20px;
      color: #ffb580;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-shadow: 0 0 5px #000000;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      padding: 0 10px;
      color: #ffb580;
      font-weight: 700;
      text-shadow: 0 0 5px #000000;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #ffb580;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #ffb580;
      color: #000000;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #ffb580, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
      .title,
      .description {
        color: white;
      }
    }
  }
}

.freelance-container {
  width: 100%;
  will-change: contents;
  height: 100%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #ffb580;
  }
}
