.category-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  top: 0;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }

  .category {
    width: calc(100% / 3);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Coolvetica';
    letter-spacing: 5px;
    font-size: 36px;
    font-weight: 800;
    text-decoration: none;
    transition: width 1s ease-in-out;

    @media only screen and (max-width: 960px) {
      width: 100%;
      height: calc(100% / 3);
      transition: height 1s ease-in-out;
    }
  }

  .upenn {
    background: linear-gradient(to top right, #011f5b 50%, #990000 50%);
    color: #ffffff;

    &:hover {
      transition: width 1s ease-in-out;
      width: 60%;

      @media only screen and (max-width: 960px) {
        width: 100%;
        height: 60%;
        transition: height 1s ease-in-out;
      }
    }
  }

  .moesif {
    background-color: #ff4f00;
    color: #272f34;

    &:hover {
      transition: width 1s ease-in-out;
      width: 60%;

      @media only screen and (max-width: 960px) {
        width: 100%;
        height: 60%;
        transition: height 1s ease-in-out;
      }
    }
  }

  .freelance {
    background: #333366;
    color: #ffb580;

    &:hover {
      transition: width 1s ease-in-out;
      width: 60%;

      @media only screen and (max-width: 960px) {
        width: 100%;
        height: 60%;
        transition: height 1s ease-in-out;
      }
    }
  }
}
