.page {
  width: calc(100% - 70px);
  height: 100%;
  position: absolute;
  margin-left: 70px;

  @media only screen and (max-width: 960px) {
    margin-left: 0;
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 70px;
  }
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;

  @media only screen and (max-width: 960px) {
    width: 100%;
    height: 100vh;
    padding-bottom: 58px;
    box-sizing: border-box;
    position: relative;
    top: 0;
  }
}

.about-page,
.contact-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffb580;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: 10px;
  }

  .text-zone {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    // float: left;
    max-height: 90%;
    text-align: left;

    @media only screen and (max-width: 960px) {
      width: 80%;
      min-height: 0;
      top: 20%;
      transform: translateY(-20%);
    }
  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    min-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.3s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.4s;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
