@import 'animate.css';
@import '~loaders.css/src/animations/ball-scale-multiple.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

$primary-color: #ffb580;

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');

input,
textarea {
  font-family: 'Helvetica';
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

::-webkit-scrollbar {
  width: 7px;

  @media only screen and (max-width: 1200px) {
    width: 4px;
  }
}

::-webkit-scrollbar-track {
  background-color: #181818;
}

::-webkit-scrollbar-thumb {
  background-color: #4d4d4e;

  &:hover {
    background-color: #ffb580;
  }
}
