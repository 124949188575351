.popup-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.popup-inner {
  box-sizing: border-box;
  position: relative;
  padding: 15px 0;
  height: 70%;
  width: 65%;
  background-color: #181818;
  z-index: 999;
  opacity: 0;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  @media only screen and (max-width: 960px) {
    min-width: 75%;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 80vh;
    margin-top: 70px;
  }

  @media only screen and (min-width: 961px) {
    width: 85vw;
    margin-left: -70px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .text-zone {
    width: 35%;
    text-align: left;
    z-index: 90;

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffb580;
      font-weight: 400;
      margin: 0;
      line-height: 1.2;
      min-width: fit-content;

      @media only screen and (max-width: 960px) {
        font-size: 48px;
        line-height: 1;
      }

      @media only screen and (max-width: 530px) {
        font-size: 36px;
        line-height: 1;
      }

      @media only screen and (min-width: 1600px) {
        font-size: 72px;
      }
    }

    p {
      color: #fff;
      font-family: sans-serif;
      font-size: 20px;
      font-weight: 300;
      min-width: fit-content;

      @media only screen and (max-width: 960px) {
        font-size: 16px;
        padding-bottom: 10px;
      }

      @media only screen and (min-width: 1600px) {
        font-size: 24px;
        padding-bottom: 20px;
      }
    }

    @media only screen and (max-width: 960px) {
      width: 70%;
      overflow-y: scroll;
      overflow-x: hidden;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    @media only screen and (min-width: 961px) {
      max-height: 85%;
      overflow-y: scroll;
      padding: 10px 15px;
    }
  }

  .close-btn {
    color: #4d4d4e;
    position: fixed;
    top: 5px;
    right: 5px;
    padding: 0;
    z-index: 999;
    border: none;
    background: none;
    font-size: 32px;

    &:hover {
      color: #ffb580;
    }

    @media only screen and (max-width: 960px) {
      position: fixed;
      top: 5px;
      font-size: 24px;
    }
  }
}

.popup-image {
  background: #4d4d4e;
  border: solid 7px #4d4d4e;

  &:hover {
    background: #ffb580;
    border: solid 7px #ffb580;
  }

  @media only screen and (max-width: 960px) {
    min-height: 20%;
    max-height: auto;
    max-width: 60%;
    margin: 25px 0;
  }

  @media only screen and (min-width: 961px) {
    max-width: 50%;
    max-height: 90%;
  }
}

.flat-button {
  color: #ffb580;
  font-size: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid #ffb580;
  text-transform: uppercase;
  float: right;
  text-align: center;
  margin-right: 10px;

  &:hover {
    background-color: #ffb580;
    color: #181818;
    border: 1px solid #181818;
  }
}
